$layout-spacer-x: 2;

.forms-wizard-alt {
  .forms-wizard {
    li {
      font-size: 1rem;

      em {
        width: 14px;
        height: 14px;
        line-height: 14px;
        text-indent: -999rem;
        border: white solid 2px;
        margin: 24px auto 0;
      }
    }
  }
}

.forms-wizard {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  display: table;
  table-layout: fixed;
  border-radius: 25rem;

  li {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    font-size: 1.25rem / 1.1;
    padding: ($layout-spacer-x / 1.5) 0;
    color: #b0bfd6; //changed
    position: relative;

    &::after,
    &::before {
      position: absolute;
      height: 4px;
      top: 50%;
      margin-top: -($layout-spacer-x / 1.5);
      width: 50%;
      content: '';
      background: #dee2e6;
      z-index: 5;
      transition: all .2s;
    }

    &::after {
      left: 50%;
    }

    &::before {
      left: 0;
    }

    &:first-child {
      &::before {
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px
      }
    }

    &:last-child {
      &::after {
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px
      }
    }

    em {
      font-style: normal;
      font-size: 1.5rem;
      background: grey;
      color: white;
      text-align: center;
      padding: 0;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50px;
      display: block;
      margin: 0 auto 0.5rem;
      position: relative;
      z-index: 7;
      transition: all .2s;
    }

    &.form-wizard-step-doing {
      color: #495057;

      em {
        background: #6391ff;
      }

      &::before {
        background: #6391ff;
      }
    }

    &.form-wizard-step-done {
      em {
        font-family: 'Linearicons-Free';
        //@extend .lnr-checkmark-circle;
        background: #454980;
        overflow: hidden;

        &::before {
          width: 42px;
          height: 42px;
          font-size: 1.2rem;
          line-height: 40px;
          text-align: center;
          display: block;
        }
      }

      &::after,
      &::before {
        background: #454980;
      }
    }

    &:hover {
      color: #6c757d;
    }
  }
}
