// 3rd-party Plugins
@import "../../node_modules/highlight.js/styles/googlecode.css";
@import "../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "../_metronic/_assets/sass/style.react";

// Default Layout themes
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "../_metronic/_assets/sass/themes/layout/brand/light";

//changed
//@import "./_metronic/_assets/sass/themes/layout/aside/light.scss";
// Light
@import "../_metronic/_assets/sass/themes/layout/aside/light";

//changed
@import "../_metronic/_assets/sass/themes/layout/brand/dark";
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

//breadcrumbs-custom
@import "components/breadcrumb-custom";
@import "components/header-btn";
// Header themes

// Light
@import "../_metronic/_assets/sass/themes/layout/header/base/light";

// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
//// Light
@import "../_metronic/_assets/sass/themes/layout/header/menu/light";

// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "../_metronic/_assets/sass/themes/layout/brand/dark";
}

// Light
.brand-light {
  @import "../_metronic/_assets/sass/themes/layout/brand/light";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

@import "../_metronic/_assets/sass/components/custom/modal-detail";
// for tooltip
@import "../_metronic/_assets/sass/components/custom/tooltip";
@import "../_metronic/_assets/sass/components/custom/help-section";
//upload Dataset css for multiple images
@import "../_metronic/_assets/sass/components/custom/upload-dataset";
//upload-Dataset-folder-css
@import "../_metronic/_assets/sass/components/custom/upload-dataset-folder";
//Annotation Responsive
@import "../_metronic/_assets/sass/components/custom/annotation";
//sign-up css in wizards
@import "../_metronic/_assets/sass/components/custom/sign-up-wizard";
//user-profile
@import "components/user-profile-line";
@import "components/dataset-upload";
//dateRangepicker
@import "components/dateRangePicker";
//annotation-tools
@import "components/annotationTools";
//social-media-icon
@import "components/social-media-icon";

// Primary colors
$primary: #147b82 !default; //#3699FF !default; // Bootstrap variable

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}
.json > pre {
  background: none #fff !important;
}
.example-code > .json {
  background: none #fff !important;
}
.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}
.react-bootstrap-table {
  overflow-x: auto;
}
.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.modal .modal-header .close span {
  display: block !important;
}
.MuiRating-label {
  font-size: 3rem !important;
  color: #fdcc0d !important;
}
.detection-image-full-view {
  width: 62vw;
  height: 62vw;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  margin-top: -31vw;
  margin-left: -31vw;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.3);
  transform: none;
  z-index: 10051;
}

.bounding-box-full__main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  opacity: 0.4;
  z-index: 10052;
}
.bounding-box-full {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  z-index: 10053;

  .bounding-box-full__sub {
    height: 100%;
    display: flex;

    .react-transform-component {
      height: 100%;
      vertical-align: middle;
      display: contents;
    }

    .bounding-box-full__menu {
      background: white;
    }
  }
}
.displays1 {
  display: flex !important;
}

@media screen and (max-width: 420px) {
  .Small {
    display: none !important;
  }
}

@media (min-width: 1200px) and(max-width: 2500px) {
  .space-left {
    margin-left: -72px !important;
  }
}
@media screen and (max-width: 330px) {
  .space-left {
    margin-left: -23px !important;
  }
}
@media screen and (max-width: 575px) {
  .displays1 {
    display: none !important;
  }
}
@media screen and (max-width: 575px) {
  .content {
    justify-content: center !important;
  }
}
@media screen and (min-width: 575px) {
  .delete_large {
    display: none !important;
  }
}

.box-customer-widget {
  flex-basis: 13.99% !important;
  flex-grow: 1;
  background-color: $primary !important;
}

@media screen and (max-width: 1366px) {
  .box-customer-widget {
    flex-basis: 29.99% !important;
    flex-grow: 1;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 1024px) {
  .box-customer-widget {
    flex-basis: 29.99% !important;
    flex-grow: 1;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 540px) {
  .box-customer-widget {
    flex-basis: 100% !important;
    flex-grow: 1;
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  my-widget3 {
    display: block;
  }
}

.my-widget:hover {
  box-shadow: 6px 8px 10px -5px rgba(0, 0, 0, 0.9);
  background-color: white;
  border-radius: 4px; /* Round corners */

  border-left: 1px solid #82d1de; /* Blue left border */
  background-image: linear-gradient(154deg, $primary, #5dbfc4);
}
.my-widget2 {
  object-fit: cover;
}
.my-widget8 {
  margin-top: 12px !important;
}
.my-widget5 {
  width: 100% !important;
}
.my-widget6 {
  width: 50% !important;
}
.disable-scrolling {
  overflow: auto !important;
  height: 100vh;
}

@media (max-width: 480px) {
  my-widget3 {
    display: block;
  }
}
@media (min-width: 480px) {
  .my-widget2 {
    flex-wrap: inherit;
  }
}

.align-left {
  text-align: left;
}
.box-customer-grid {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  flex-wrap: wrap;
}
.fileContainer .chooseFileButton:hover {
  background: #66dfe7 !important;
}
.modal-contain-title-part {
  background: $primary;
  color: white;
  //margin: 20px auto;
}
.font-color-common {
  color: $primary;
  font-family: Rubik, sans-serif;
}

.new-nav {
  .nav-item:hover {
    background-color: #ffffff !important;
    color: $primary !important;
  }

  .nav-tabs {
    .nav-link:hover {
      border-color: #ffffff !important;
    }
  }
}
.new-nav .nav-link.active,
.new-nav .nav-item.show .nav-link {
  border-color: transparent !important;
}
.new-nav .nav-link:hover,
.new-nav .nav-link:focus {
  border-color: transparent !important;
}

.rounded-xl-focus:hover {
  background-color: $primary;
  color: #fff;
  border: 2px dashed $primary;
  cursor: pointer;
  i {
    color: #fff;
  }
}
.dropdown-toggle-btn {
  background-color: white !important;
  border: white !important;
}
.icon-hover:hover {
  color: #3f4254 !important;
}
.text-hover-btn {
  background-color: white !important;
}
.rounded-xl-focus-active {
  background-color: $primary !important;
  color: #fff !important;
  border: 2px dashed $primary !important;
  cursor: pointer !important;
  i {
    color: #fff !important;
  }
}

.rounded-xl-focus {
  background-color: #fff;
  color: $primary;
  border: 2px dashed $primary;
  cursor: pointer;
  i {
    color: $primary;
  }
}
.custom-tabs .tab-content {
  //height: 85vh !important;
  overflow-y: hidden !important;
  overflow-x: hidden;
}
//custom-chip
.custome-chip .MuiChip-clickable:hover,
.custome-chip .MuiChip-clickable:focus {
  background-color: #66dfe7 !important;
}
.custome-chip .MuiChip-root {
  border-radius: 6px;
  font-size: 1rem;
  height: 34px;
}
.custome-chip .makeStyles-root-38 {
  display: block;
}
.custome-chip .MuiCheckbox-colorPrimary.Mui-checked {
  color: $primary !important;
}
.custome-chip .MuiCheckbox-root {
  color: $primary !important;
}

.icon-color {
  color: $primary !important;
}

.button-centered-element {
  margin: 0;
  position: absolute;
  bottom: 5%;
  right: 5%;
}

.centered-element-default {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



.btn_of_upload {
  border: none;
  border-radius: 25px;
  color: #ffffff !important;
  background: $primary;
  font-size: 14px;
  font-weight: 300;
  padding: 9px 26px;

  &:hover {
    background: #66dfe7 !important;
  }
}

.modalNewSize {
  max-width: calc(100% - 380px) !important;
}

.short-label-name-length {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.weekdays-font {
  width: 40px !important;
}
.pagination-tab {
  display: flex !important;
  justify-content: right !important;
}

.user-mainCard {
  background: white;
  box-shadow: 0 0 6px #00000029;
  border-radius: 5px;
  cursor: pointer;
  //height: 290px !important;
  //max-height: 290px !important;
}
.filter-menu-onclick {
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 500;
  background: $primary;
  color: #fff;

  .svg-icon svg g [fill] {
    fill: #fff !important;
  }
}
.filter-menu {
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 500;

  .svg-icon svg g [fill] {
    fill: $primary !important;
  }
}
.filter-menu:hover {
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 500;
  background: $primary;
  color: #fff;

  .svg-icon svg g [fill] {
    fill: #fff !important;
  }
}
.filter-radio {
  .MuiTypography-body1 {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}
.user-popper-width {
  width: 505px !important;
  max-width: 505px !important;
}
.borderStyle {
  border: 1px solid #e2e2e2;
}
.table-center {
  display: flex;
  justify-content: center;
}
.input-validation-star {
  color: $danger-hover;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: $primary !important;
}
.MuiCheckbox-root {
  color: $primary !important;
}
.MuiSwitch-colorPrimary.Mui-checked {
  color: $primary !important;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: $primary !important;
}
.table-switch .MuiSwitch-root {
  width: 50px !important;
  height: 35px !important;
}
.table-switch .MuiSwitch-thumb {
  width: 17px !important;
  height: 17px !important;
}
.table-dark {
  background-color: $primary !important;
}
.radio-color {
  accent-color: $primary !important;
}
.theme-color {
  .MuiSvgIcon-root {
    color: $primary !important;
  }
}
.MuiTooltip-tooltip {
  background-color: $primary !important;
}
.project-table-name-length {
  display: block;
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.experiment-table-name-length {
  display: block;
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}
.dropdown-menu-override.dropdown-menu.show {
  transform: translate3d(-100px, 37px, 0px) !important;
}
.dropdown-menu-override-css.dropdown-menu.show {
  transform: translate3d(-30px, 37px, 0px) !important;
}
.dropdown-menu-color:hover {
  background-color: #f3f6f9 !important;
}

//scrollbar start
#style-2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style-2::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

#style-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $primary;
}
//scrollbar end

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .centered-element {
    margin: 0;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .scrollbar {
    float: left;
    height: 600px !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .force-overflow {
    min-height: 700px !important;
  }

  .custom-bootstrap-table {
    max-height: 364px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .centered-element {
    margin: 0;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .scrollbar {
    float: left;
    height: auto;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .force-overflow {
    min-height: auto;
  }

  .custom-bootstrap-table {
    max-height: 430px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .centered-element {
    margin: 0;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .scrollbar {
    float: left;
    height: 650px !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .force-overflow {
    min-height: 750px !important;
  }

  .custom-bootstrap-table {
    max-height: 420px;
  }
}

// Large devices (desktops, 992px to 1199.98px)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .centered-element {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .scrollbar {
    float: left;
    height: 650px !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .force-overflow {
    min-height: 750px !important;
  }

  //.custom-bootstrap-table {
  //  max-height: 394px;
  //}
}

// Extra large devices (large desktops, 1200px to 1440px)
@media (min-width: 1200px) and (max-width: 1440px) {
  .centered-element {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .scrollbar {
    float: left;
    height: 650px !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .force-overflow {
    min-height: 750px !important;
  }

  //.custom-bootstrap-table {max-height: 635px;}
}

// Extra large devices (large desktops, 1441px and up)
@media (min-width: 1441px) {
  .centered-element {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .scrollbar {
    float: left;
    height: 500px !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .force-overflow {
    min-height: 600px !important;
  }

  .scrollbar-project-create {
    float: left;
    height: 300px !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .force-overflow-project-create {
    min-height: 400px !important;
  }

  //.custom-bootstrap-table {max-height: 635px;}
}

.react-bs-table-sizePerPage-dropdown .dropdown-menu {
  top: -100% !important;
  transform: translateY(-100%) !important;
}

.modal-background-color{
  background: #00000080 !important;
}


.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #147b82  !important;
  border-color: #147b82 !important;
  color: #fff;
}

.css-1hrukl9.Mui-selected {
  background-color: #147b82  !important;
  border-color: #147b82 !important;
  color: #fff;
}

.css-ub1r1.Mui-selected{

  background-color: #147b82  !important;
  border-color: #147b82 !important;
  color: #fff;
}
.RtspDatsetCard{
  margin-right: -5px !important;
}
.modal-open {
  overflow: hidden !important;
}