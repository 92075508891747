
//this css is avilable both side of card in annotation and helpsection
.my-widget:hover {
  box-shadow: 6px 8px 10px -5px rgba(0, 0, 0, 0.9);
  //background-color: white;
  border-radius: 2px; /* Round corners */
  //border-left: 1px solid #434d7d; /* Blue left border */
}
.popup{
  //border: 0 solid transparent;
  border-left: 24px solid white;
  border-radius: 33px 0;
}
@media only screen and (max-width:990px) {
  .my-widget {
   display:  flex !important;
    flex-direction: column !important;
  }
}
.hh{
  margin-right: 385px !important;
}
.cards-title{
  margin-left: 40px !important;
}

  .hhg_i{
    max-height: 480px !important;
    min-width:500px !important;
    max-width:510px !important;
  }

@media only screen  and (max-width:480px) {
  .hhg_i{
    max-height: 480px !important;
    min-width:339px !important;
    max-width:510px !important;
  }}
@media (min-width:768px) and (max-width:1024px) {
  .hhg_i {
    max-height: 480px !important;
    min-width:333px !important;
    max-width:510px !important;
  }}
.label_width{
  max-width: 230px !important;
}

@media (min-width:360px) and (max-width:410px) {
.pop{
  margin-top: 35px !important;
}
}