@media (min-width: 1200px) {

  .header-btn{
    margin-left: -15px;
  }

}

.header-title {
  font-weight: 500 !important;
}