//annotation tools css
.annotation-tool-dataset-name{
  display: block!important;
  white-space: nowrap!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
}

.annotation-tool-full-screen{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  height: 100vh;
  width: 100vw;
}

.annotation-image-checkbox-new{
  z-index: 2;
  left: 1.25rem;
  top: 1.25rem;
  position: absolute;
}


.checkbox-new {
  z-index: 2;
  left: 2.25rem;
  top: 1.25rem;
  position: absolute;
}




@media screen and (max-width:768px) {
  .checkbox-new {
    z-index: 2;
    left: 4.0rem;
    top: 1.25rem;
    position: absolute;
  }
}

.showHover {
  display: none;
}

.folderBox:hover .showHover {
  display: block;
  position: absolute;
  //top: 5px;
  top: -34px;
  left: 4px;
}
.image-eye{
  position: absolute;
  top: 5px;
  padding: 3px 5px;
  border-radius: 5px;
  right: 0px;
  background: #ffffffcc;
  //background: rgba(255 255 255 /85%);
}

.css-1kuq5xv-MuiButtonBase-root-MuiIconButton-root{
  color: #147b82 !important;
}
.styles-module_wrapper__1I_qj{
  z-index: 9999 !important;
}

.styles-module_wrapper__1I_qj{
  z-index: 1;
  display: flex;
  align-items: center;
  position: fixed;
  padding: 0px 60px 0px 60px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  box-sizing: border-box;
}

.styles-module_close__2I1sI {
  color: white;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 40px;
  font-weight: bold;
  opacity: 0.2;
  cursor: pointer;
}


.styles-module_images_count {
  margin-left: 10px;
  color: white;
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 40px;
  font-weight: bold;
  opacity: 0.2;
  cursor: pointer;
}



.styles-module_prev__KqFRp {
  left: 0;
}

.styles-module_navigation__1pqAE{
  height: 80%;
  color: white;
  cursor: pointer;
  position: absolute;
  font-size: 60px;
  line-height: 60px;
  font-weight: bold;
  display: flex;
  align-items: center;
  opacity: 0.2;
  padding: 0 15px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.styles-module_next__1uQwZ {
  right: 0;
}

.styles-module_navigation__1pqAE {
  height: 80%;
  color: white;
  cursor: pointer;
  position: absolute;
  font-size: 60px;
  line-height: 60px;
  font-weight: bold;
  display: flex;
  align-items: center;
  opacity: 0.2;
  padding: 0 15px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.styles-module_content__2jwZj {
  margin: auto;
  padding: 0;
  width: 90%;
  height: 100%;
  max-height: 100%;
  text-align: center;
}

.styles-module_slide__1zrfk {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.css-39nlm1 {
  color: #147b82 !important;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .grid-row {
    grid-template-columns: repeat(1, minmax(170px, 0fr));
    grid-auto-rows: minmax(170px, auto);
    display: grid;
    justify-content: space-evenly;


  }
  .grid-row-raw{
    grid-template-columns: repeat(1, minmax(170px, 1fr));
    grid-auto-rows: minmax(170px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }


  .upload-image-row{
    grid-template-columns: repeat(1, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }

  .annotation-folder{
    height: 600px !important;
    max-height: 600px;
    overflow-y: auto;
  }

  .annotatioon-image-card-raw{
    height: 48vh;
    max-height: 48vh;
    overflow-y: auto;
  }

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .grid-row {
    grid-template-columns: repeat(1, minmax(170px, 0fr));
    grid-auto-rows: minmax(170px, auto);
    display: grid;
    justify-content: space-evenly;
  }

  .grid-row-raw{
    grid-template-columns: repeat(1, minmax(170px, 1fr));
    grid-auto-rows: minmax(170px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }

  .upload-image-row{
    grid-template-columns: repeat(2, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }

  .annotatioon-image-card-raw{
    height: 48vh;
    max-height: 48vh;
    overflow-y: auto;
  }

  .annotation-folder{
    height: 600px !important;
    max-height: 600px;
    overflow-y: auto;
  }
}


// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .grid-row {
    grid-template-columns: repeat(3, minmax(180px, 0fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-content: space-evenly;

  }

  .grid-row-raw{
    grid-template-columns: repeat(3, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }


  .upload-image-row{
    grid-template-columns: repeat(2, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }

  .annotation-folder{
    height: 600px !important;
    max-height: 600px;
    overflow-y: auto;
  }

  .annotatioon-image-card-raw{
    height: 53vh;
    max-height: 53vh;
    overflow-y: auto;
  }

  .table-height{
    height: 355px !important;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .grid-row {
    grid-template-columns: repeat(4, minmax(170px, 0fr));
    grid-auto-rows: minmax(170px, auto);
    display: grid;
    justify-content: space-evenly;
  }
  .grid-row-raw{
    grid-template-columns: repeat(4, minmax(170px, 1fr));
    grid-auto-rows: minmax(170px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }

  .upload-image-row{
    grid-template-columns: repeat(4, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }

  .annotation-folder{
    height: 600px !important;
    max-height: 600px;
    overflow-y: auto;
  }

  .annotatioon-image-card-raw{
    height: 48vh;
    max-height: 48vh;
    overflow-y: auto;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1440px) {
  .grid-row {
    grid-template-columns: repeat(5, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-content: space-evenly;

  }
  .grid-row-raw{
    grid-template-columns: repeat(5, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }

  .upload-image-row{
    grid-template-columns: repeat(5, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }

  .annotation-folder{
    height: 600px !important;
    max-height: 600px;
    overflow-y: auto;
  }

  .annotatioon-image-card-raw{
    height: 48vh;
    max-height: 48vh;
    overflow-y: auto;
  }

}

.table-height{
  height: 470px !important;
}
@media (min-width: 1441px) {
  .grid-row {
    grid-template-columns: repeat(6, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-content: space-evenly;
  }
  .grid-row-raw{
    grid-template-columns: repeat(6, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }

  .upload-image-row{
    grid-template-columns: repeat(5, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }

  .annotation-folder{
    height: 670px !important;
    max-height: 670px;
    overflow-y: auto;
  }


  .upload-image-row{
    grid-template-columns: repeat(5, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }

    .annotatioon-image-card-raw{
      height: 48vh;
      max-height: 48vh;
      overflow-y: auto;
    }

  .table-height {
    height: 610px !important;
    overflow-y: hidden !important;
  }
}
@media (min-width: 1441px)  and (max-width: 1700px){
  .grid-row {
    grid-template-columns: repeat(6, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-content: space-evenly;
  }
  .grid-row-raw{
    grid-template-columns: repeat(7, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }

  .upload-image-row{
    grid-template-columns: repeat(5, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }

  .annotatioon-image-card-raw{
    height: 48vh;
    max-height: 48vh;
    overflow-y: auto;
  }
}
@media (min-width: 1701px)  and (max-width: 1900px){
  .grid-row {
    grid-template-columns: repeat(7, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-content: space-evenly;
  }
  .grid-row-raw{
    grid-template-columns: repeat(7, minmax(116px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }

  .upload-image-row{
    grid-template-columns: repeat(5, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }
}
@media (min-width: 1901px) and (max-width: 2080px){
  .grid-row {
    grid-template-columns: repeat(10, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-content: space-evenly;
  }
  .grid-row-raw{
    grid-template-columns: repeat(10, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }
  .upload-image-row{
    grid-template-columns: repeat(5, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }
}
@media (min-width: 2081px) and (max-width: 2300px){
  .grid-row-raw{
    grid-template-columns: repeat(9, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }
  .upload-image-row{
    grid-template-columns: repeat(5, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }
}
@media (min-width: 2301px)and (max-width: 2545px) {
  .grid-row {
    grid-template-columns: repeat(11, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-content: space-evenly;
  }
  .grid-row-raw{
    grid-template-columns: repeat(11, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }

  .upload-image-row{
    grid-template-columns: repeat(5, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }
}
@media (min-width: 2546px) and(max-width: 2800px) {
  .grid-row {
    grid-template-columns: repeat(12, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-content: space-evenly;
  }
  .grid-row-raw{
    grid-template-columns: repeat(14, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }

  .upload-image-row{
    grid-template-columns: repeat(5, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }
}
@media (min-width: 2801px) and (max-width: 2950px){
  .grid-row {
    grid-template-columns: repeat(15, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-content: space-evenly;
  }
  .grid-row-raw{
    grid-template-columns: repeat(15, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }

  .upload-image-row{
    grid-template-columns: repeat(5, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }
}
@media (min-width: 2951px) and (max-width: 3000px) {
  .grid-row {
    grid-template-columns: repeat(16, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-content: space-evenly;
  }
  .grid-row-raw{
    grid-template-columns: repeat(16, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }

  .upload-image-row{
    grid-template-columns: repeat(5, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }
}
@media (min-width: 3001px) and (max-width: 3270px) {
  .grid-row {
    grid-template-columns: repeat(17, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-content: space-evenly;
  }
  .grid-row-raw{
    grid-template-columns: repeat(17, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }

  .upload-image-row{
    grid-template-columns: repeat(5, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }
}
@media (min-width: 3271px)  and (max-width: 3600px){
  .grid-row {
    grid-template-columns: repeat(18, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-content: space-evenly;
  }
  .grid-row-raw{
    grid-template-columns: repeat(18, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }

  .upload-image-row{
    grid-template-columns: repeat(5, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }
}
@media (min-width: 3601px){
  .grid-row {
    grid-template-columns: repeat(19, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-content: space-evenly;
  }
  .grid-row-raw{
    grid-template-columns: repeat(19, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
    user-select: none;
  }
  .upload-image-row{
    grid-template-columns: repeat(5, minmax(170px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    display: grid;
    justify-items: center;
  }
}