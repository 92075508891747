@media (max-width: 1200px) {
  .user-profile-line{
    margin-top: 20px;
  }
}
@media (min-width: 1200px) {
  .user-profile-name{
    padding-top: 29px;
  }
}
