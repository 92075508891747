  .help{
    margin-top: 10px;
  }
  .nav-item:hover{

    background-color: #147b82 !important; //#434d7d !important;
    color: #FFFFFF !important;
    //transition: background-color .5s;
  }
  .transform-component1{
    transform: translate(0,0) !important;
    background-color: #66dfe7 !important; //#00aff0 !important;
  }