//
// Checkbox
//


@mixin radio-solid-theme($bg-color, $checked-bg-color, $checked-tick-color) {
  // Default state
  > span {
    background-color: $bg-color;
    border: 1px solid transparent;
  }

  // Focus state
  > input:focus ~ span {
    border: 1px solid transparent;
  }

  // Checked state
  > input:checked ~ span {
    background-color: $checked-bg-color;

    &:after {
      background-color: $checked-tick-color;
      border-color: $checked-tick-color;
    }
  }
}

@mixin radio-outline-theme($outline-color, $checked-outline-color, $checked-tick-color) {
  // Default state
  > span {
    background-color: transparent;
    border-color: $outline-color;
  }

  // Focus state
  > input:focus ~ span {
    background-color: transparent;
    border-color: $outline-color;
  }

  // Checked state
  > input:checked ~ span {
    background-color: transparent;
    border-color: $checked-outline-color;

    &:after {
      background-color: $checked-tick-color;
      border-color: $checked-tick-color;
    }
  }
}

@mixin radio-accent-theme($bg-color, $checked-border-color) {
  // Default state
  > span {
    &:after {
      background-color: $bg-color;
    }
  }

  // Focus state
  > input:focus ~ span {
    border-color: $checked-border-color;

    &:after {
      background-color: $bg-color;
    }
  }

  // Checked state
  > input:checked ~ span {
    border-color: $checked-border-color;

    &:after {
      background-color: $bg-color;
    }
  }
}

@mixin radio-size($base, $tick) {
  > span {
    height: $base;
    width: $base;

    &:after {
      width: $tick;
      height: $tick;
    }
  }
}
