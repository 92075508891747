.remove-scroll::-webkit-scrollbar {
  width: 0px;
}

.remove-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0);
}

.remove-scroll::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}