.fileContainer .uploadPictureContainer{
  width: 10%;
  margin: 2%;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  box-shadow: #147b82; //#5195ff;
  border: 1px solid #d0dbe4;
  position: relative;
}

@media (max-width: 576px){
  .fileContainer .uploadPictureContainer {
    width: 45% !important;
    margin: 2% !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .fileContainer .uploadPictureContainer {
    width: 35% !important;
    margin: 2% !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .fileContainer .uploadPictureContainer {
    width: 35% !important;
    margin: 2% !important;
  }

}

// Large devices (desktops, 992px to 1199.98px)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .fileContainer .deleteImage {
    position: absolute;
    top: -9px;
    right: -9px;
    background: #147b82; //#5195ff;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 5px;
    font-weight: bold;
    line-height: 12px;
    width: 10px;
    height: 10px;
  }
  .fileContainer .uploadPictureContainer{
    width: 20% !important;
    margin: 2% !important;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    box-shadow: #147b82; //#5195ff;
    border: 1px solid #d0dbe4;
    position: relative;
  }
}
@media (min-width: 1200px) {
  .fileContainer .uploadPictureContainer {
    width: 15% !important;
    margin: 2% !important;
  }
}

.fileContainer {
  width: 100% !important;
  border-radius: 10px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  box-shadow: none !important;
  //margin: 10px auto;
}
.fileContainer .chooseFileButton {
  padding: 8px 26px !important;
  background: #147b82 !important;
  border-radius: 30px;
  font-weight: 300;
  font-size: 15px;
  margin: 1px 0;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: none;
}
.fileContainer .chooseFileButton :hover{
  background: #147b82; // #5195ff;
}

.my-preview-img{
  height: 100px;
  margin: 5px;
}
.scroll-bar{
  overflow: hidden
}
//
.btn-primarysss {
  color: #fff;
}

/*Overriding  react-images-upload  css  */

.fileContainer .deleteImage {
  position: absolute;
  top: -9px;
  right: -9px;
  color: #fff;
  background: #147b82;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 14px !important;
  font-weight: bold;
  line-height: 21px !important;
  width: 18px !important;
  height: 18px !important;
}
//.fileContainer .deleteImage {
//  position: absolute;
//  top: -9px;
//  right: -9px;
//  background: #147b82; //#5195ff;
//  border-radius: 50%;
//  text-align: center;
//  cursor: pointer;
//  font-size: 11px;
//  font-weight: bold;
//  line-height: 21px;
//  width: 22px;
//  height: 22px;
//}
.fileContainer .uploadPictureContainer img.uploadPicture {
  width: 100% !important;
  height: 100px !important;
}
.fileContainer .uploadPictureContainer .deleteImage {
  height: 20px !important;
}