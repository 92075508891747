.image_of_viewer{
  height:180px !important;
  width:185px !important;
  //style={{height: "180px",width:"185px"}}

}

.results-modal{
  .modal-content{
    max-width: 1300px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.more {
  text-align: inherit;
  font-size: 20px;
  font-weight: 600;
}
.image_of_card{
width: 185px !important;
  cursor: pointer !important;

  // style={{ width: "185px", cursor: "pointer" }}
}
.upload_rtsp_btns {
  position: absolute;
  bottom: 0;
  right: 8px;
}

.rtsp-detail-card {
  position: relative;
  max-width: 320px;
  width: 320px;
  min-width: 320px;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 15px;
  overflow: hidden;
  height: fit-content;
  margin: 10px 18px;

  .rtsp-edit-btn {
    position: absolute;
    top: 12px;
    right: 8px;
  }

  .rtsp-url {
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 6px;
    text-decoration: underline;
    cursor: pointer;
    color: #147b82;
  }

  hr {
    margin: 2px 0;
  }

  .field-title {
    font-weight: 500;
  }
}

.dataset-viewver-class{
  //width:"700px",height:"700px"}}
  width:700px !important;
  height:700px !important;
}
.dataset_height{
  max-height: 790px !important;
  overflow-y: auto !important;
  min-height: 750px !important;
}
.dataset-Modal{
  //style={{display:"flex",flexDirection:"row-reverse",marginBottom:"-9px"}}
  display: flex !important;
  flex-direction: row-reverse !important;
  margin-bottom: -9 px !important;
}
//.Dataset-upload-images{
//  margin-left: 3px !important;
//  margin-right: -12px !important;
//position: relative !important;
//  display: flex !important;
//  flex-wrap: wrap !important;
//  justify-content: space-around !important;
//}
//style={{width:'188px',height:'188px',marginRight:"11px",marginTop:"15px"}}
.dataset-upload-card{
width:188px !important;
  height: 188px !important;
  margin-right: 11px !important;
  margin-top: 15px !important;
}
.dataset-Viewvwe{
margin-top: 11px!important;
}
@media screen and (max-width: 2500px) {
  .results-modal{
        .modal-content{
          width: 1028px !important;
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }  .dataset-upload-images {
        margin-left: 3px !important;
        margin-right: -12px !important;
        position: relative !important;
        display: flex !important;
        flex-wrap: wrap !important;
        //justify-content: space-between !important;
      }
}@media screen and (max-width: 1338px) {
  .results-modal{
        .modal-content{
          width: 815px !important;
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }  .dataset-upload-images {
        margin-left: 3px !important;
        margin-right: -12px !important;
        position: relative !important;
        display: flex !important;
        flex-wrap: wrap !important;
        //justify-content: space-between !important;
      }
}@media screen and (max-width: 1040px) {
  .results-modal{
        .modal-content{
          width: 606px !important;
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }  .dataset-upload-images {
        margin-left: 3px !important;
        margin-right: -12px !important;
        position: relative !important;
        display: flex !important;
        flex-wrap: wrap !important;
        //justify-content: space-between !important;
      }
  .dataset-upload-card{
          width:135px !important;
          height: 135px !important;
          margin-right: 11px !important;
          margin-top: 15px !important;
        }
        .image_of_viewer{
          height:125px !important;
          width:130px !important;
          //style={{height: "180px",width:"185px"}}

        }
        .image_of_card{
          width: 135px !important;
          cursor: pointer !important;

          // style={{ width: "185px", cursor: "pointer" }}
        }
}

@media screen and (max-width: 950px) {

  .more{
    text-align: start;
    font-size: 11px;
    font-weight: 600;
    display: contents;
  }
  .results-modal{
        .modal-content{
          width: 470px !important;
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }  .dataset-upload-images {
        margin-left: 3px !important;
        margin-right: -12px !important;
        position: relative !important;
        display: flex !important;
        flex-wrap: wrap !important;
        //justify-content: space-between !important;
      }
  .dataset-upload-card{
          width:140px !important;
          height: 140px !important;
          margin-right: 11px !important;
          margin-top: 15px !important;
        }
        .image_of_viewer{
          height:130px !important;
          width:135px !important;
          //style={{height: "180px",width:"185px"}}

        }
        .image_of_card{
          width: 140px !important;
          cursor: pointer !important;

          // style={{ width: "185px", cursor: "pointer" }}
        }
}

@media screen and (max-width: 860px) {
  .results-modal{
        .modal-content{
          width: 424px !important;
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }  .dataset-upload-images {
        margin-left: 3px !important;
        margin-right: -12px !important;
        position: relative !important;
        display: flex !important;
        flex-wrap: wrap !important;
        //justify-content: space-between !important;
      }
  .dataset-upload-card{
          width:125px !important;
          height: 125px !important;
          margin-right: 11px !important;
          margin-top: 15px !important;
        }
        .image_of_viewer{
          height:115px !important;
          width:115px !important;
          //style={{height: "180px",width:"185px"}}

        }
        .image_of_card{
          width: 125px !important;
          cursor: pointer !important;

          // style={{ width: "185px", cursor: "pointer" }}
        }
  .dataset_height{
          max-height: 600px !important;
          overflow-y: auto !important;
          min-height: 600px !important
        }
}

@media screen and (max-width: 792px) {
  .results-modal{
        .modal-content{
          width: 409px !important;
          margin-left: -14px !important;
          margin-right: auto !important;
        }
      }  .dataset-upload-images {
        margin-left: 3px !important;
        margin-right: -12px !important;
        position: relative !important;
        display: flex !important;
        flex-wrap: wrap !important;
        //justify-content: space-between !important;
      }
  .dataset-upload-card{
          width:120px !important;
          height: 120px !important;
          margin-right: 11px !important;
          margin-top: 15px !important;
        }
        .image_of_viewer{
          height:110px !important;
          width:110px !important;
          //style={{height: "180px",width:"185px"}}

        }
        .image_of_card{
          width: 120px !important;
          cursor: pointer !important;

          // style={{ width: "185px", cursor: "pointer" }}
        }
}

@media screen and (max-width: 750px) {
  .results-modal{
        .modal-content{
          width: 409px !important;
          margin-left: -42px !important;
          margin-right: auto !important;
        }
      }  .dataset-upload-images {
        margin-left: 3px !important;
        margin-right: -12px !important;
        position: relative !important;
        display: flex !important;
        flex-wrap: wrap !important;
        //justify-content: space-between !important;
      }
  .dataset-upload-card{
          width:120px !important;
          height: 120px !important;
          margin-right: 11px !important;
          margin-top: 15px !important;
        }
        .image_of_viewer{
          height:110px !important;
          width:110px !important;
          //style={{height: "180px",width:"185px"}}

        }
        .image_of_card{
          width: 120px !important;
          cursor: pointer !important;

          // style={{ width: "185px", cursor: "pointer" }}
        }
}

@media screen and (max-width: 650px) {
  .results-modal{
        .modal-content{
          width: 382px !important;
          margin-left: -65px !important;
          margin-right: auto !important;
        }
      }  .dataset-upload-images {
        margin-left: 3px !important;
        margin-right: -12px !important;
        position: relative !important;
        display: flex !important;
        flex-wrap: wrap !important;
        //justify-content: space-between !important;
      }
  .dataset-upload-card{
          width:110px !important;
          height: 110px !important;
          margin-right: 11px !important;
          margin-top: 15px !important;
        }
        .image_of_viewer{
          height:110px !important;
          width:110px !important;
          //style={{height: "180px",width:"185px"}}

        }
        .image_of_card{
          width: 110px !important;
          cursor: pointer !important;

          // style={{ width: "185px", cursor: "pointer" }}
        }
}

@media screen and (max-width: 600px) {
  .results-modal{
        .modal-content{
          width: 298px !important;
          margin-left: -21px !important;
          margin-right: auto !important;
        }
      }  .dataset-upload-images {
        margin-left: 3px !important;
        margin-right: -12px !important;
        position: relative !important;
        display: flex !important;
        flex-wrap: wrap !important;
        //justify-content: space-between !important;
      }
  .dataset-upload-card{
          width:130px !important;
          height: 130px !important;
          margin-right: 11px !important;
          margin-top: 15px !important;
        }
        .image_of_viewer{
          height:130px !important;
          width:130px !important;
          //style={{height: "180px",width:"185px"}}

        }
        .image_of_card{
          width: 130px !important;
          cursor: pointer !important;

          // style={{ width: "185px", cursor: "pointer" }}
        }
}

@media screen and (max-width: 575px) {
  .more{
    text-align: start;
    font-size: 11px;
    font-weight: 600;
   }
  .dataset_height{
    max-height: 420px !important;
    overflow-y: auto !important;
    min-height: 420px !important
  }
  .results-modal{
        .modal-content{
          width: 298px !important;
          margin-left: 113px !important;
          margin-right: auto !important;
        }
      }  .dataset-upload-images {
        margin-left: 3px !important;
        margin-right: -12px !important;
        position: relative !important;
        display: flex !important;
        flex-wrap: wrap !important;
        //justify-content: space-between !important;
      }
  .dataset-upload-card{
          width:130px !important;
          height: 130px !important;
          margin-right: 11px !important;
          margin-top: 15px !important;
        }
        .image_of_viewer{
          height:130px !important;
          width:130px !important;
          //style={{height: "180px",width:"185px"}}

        }
        .image_of_card{
          width: 130px !important;
          cursor: pointer !important;

          // style={{ width: "185px", cursor: "pointer" }}
        }
}

@media screen and (max-width: 500px) {
  .results-modal{
        .modal-content{
          width: 258px !important;
          margin-left: 97px !important;
          margin-right: auto !important;
        }
      }  .dataset-upload-images {
        margin-left: 3px !important;
        margin-right: -12px !important;
        position: relative !important;
        display: flex !important;
        flex-wrap: wrap !important;
        //justify-content: space-between !important;
      }
  .dataset-upload-card{
          width:110px !important;
          height: 110px !important;
          margin-right: 11px !important;
          margin-top: 15px !important;
        }
        .image_of_viewer{
          height:110px !important;
          width:110px !important;
          //style={{height: "180px",width:"185px"}}

        }
        .image_of_card{
          width: 110px !important;
          cursor: pointer !important;

          // style={{ width: "185px", cursor: "pointer" }}
        }
}

@media screen and (max-width: 430px) {
  .more{
    text-align: start;
    font-size: 8px;
    font-weight: 600;
  }
  .results-modal{
        .modal-content{
          width: 218px !important;
          margin-left: 89px !important;
          margin-right: auto !important;
        }
      }  .dataset-upload-images {
        margin-left: 3px !important;
        margin-right: -12px !important;
        position: relative !important;
        display: flex !important;
        flex-wrap: wrap !important;
        //justify-content: space-between !important;
      }
  .dataset-upload-card{
          width:90px !important;
          height: 90px !important;
          margin-right: 11px !important;
          margin-top: 15px !important;
        }
        .image_of_viewer{
          height:90px !important;
          width:90px !important;
          //style={{height: "180px",width:"185px"}}

        }
        .image_of_card{
          width: 90px !important;
          cursor: pointer !important;

          // style={{ width: "185px", cursor: "pointer" }}
        }
}@media screen and (max-width: 400px) {
  .more{
    text-align: start;
    font-size: 8px;
    font-weight: 600;
  }
  .results-modal{
        .modal-content{
          width: 218px !important;
          margin-left: 76px !important;
          margin-right: auto !important;
        }
      }  .dataset-upload-images {
        margin-left: 3px !important;
        margin-right: -12px !important;
        position: relative !important;
        display: flex !important;
        flex-wrap: wrap !important;
        //justify-content: space-between !important;
      }
  .dataset-upload-card{
          width:90px !important;
          height: 90px !important;
          margin-right: 11px !important;
          margin-top: 15px !important;
        }
        .image_of_viewer{
          height:90px !important;
          width:90px !important;
          //style={{height: "180px",width:"185px"}}

        }
        .image_of_card{
          width: 90px !important;
          cursor: pointer !important;

          // style={{ width: "185px", cursor: "pointer" }}
        }
}

@media screen and (max-width: 380px) {
  .results-modal{
        .modal-content{
          width: 198px !important;
          margin-left: 78px !important;
          margin-right: auto !important;
        }
      }  .dataset-upload-images {
        margin-left: 3px !important;
        margin-right: -12px !important;
        position: relative !important;
        display: flex !important;
        flex-wrap: wrap !important;
        //justify-content: space-between !important;
      }
  .dataset-upload-card{
          width:80px !important;
          height: 80px !important;
          margin-right: 11px !important;
          margin-top: 15px !important;
        }
        .image_of_viewer{
          height:80px !important;
          width:80px !important;
          //style={{height: "180px",width:"185px"}}

        }
        .image_of_card{
          width: 80px !important;
          cursor: pointer !important;

          // style={{ width: "185px", cursor: "pointer" }}
        }
}

.uploadDataset-image-width {
  width: 50px !important;
  height: 50px !important;
}

.dataset-s3-input {
  display: block !important;
  width: 100%  !important;
  height: calc(1.5em + 1.3rem + 2px) !important;
  padding: 0.65rem 1rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #3F4254 !important;
  background-color: #ffffff !important;
  background-clip: padding-box !important;
  border: 1px solid #E4E6EF !important;
  border-radius: 0.42rem !important;
  box-shadow: none !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.dataset-s3-input-eye {
  border: 1px solid #E4E6EF !important;
  padding: 0.65rem 1rem !important;
  border-radius : 0.42rem !important
}

.Upload-dataset-video-font {
  font-size: 12px;
  margin: 8px 0 4px;
}
.Upload-dataset-preview {
  $previewSize: 150px;

  &__item {
    border: 2px solid gray;
    border-radius: 8px;
    color: gray;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    height: $previewSize;
    overflow: hidden;
    padding: 8px 20px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: $previewSize;

    &:not(:first-child) {
      margin-left: 8px;
    }

    &__state {
      left: 0;
      height: 100%;
      opacity: 0.5;
      position: absolute;
      top: 0;
      transition: width 0.1s;
      width: 0;

      &--uploading {
        background-color: blue;
      }

      &--complete {
        background-color: green;
        opacity: 0;
        transition: opacity 2s;
      }
    }

    &--image {
      padding: 0;
    }

    &__image {
      height: 100%;
      object-fit: cover;
      transition: height 0.2s, width 0.2s;
      width: 100%;
    }

    &--image:hover &__image {
      height: 120%;
      width: 120%;
    }

    &--pdf {
      padding: 0;
    }

    &__pdf {
      border: 0;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &--video {
      padding: 0;
    }

    &__video {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}

.upload-dataset-btn {
  display: inline-block;
  overflow: hidden;
  position: relative;

  &__input {
    font-size: 100px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
  }
}